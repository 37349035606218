async function initMap(id, lat, lng, zoom) {
    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    
    let map = new Map(document.getElementById(id), {
        center: {
            lat: lat,
            lng: lng
        },
        zoom: zoom,
        mapId: 'd09be3cce6424307',
        disableDefaultUI: true
    });

    let marker = new AdvancedMarkerElement({
        position: {
            lat: lat,
            lng: lng
        },
        map: map,
        title: "Metanous",
    });
};

class MobileNav {

    id;

    constructor(id) {
        this.id = id;

        document.querySelector('[href="#' + this.id + '"]').addEventListener('click', function (event) {
            event.preventDefault();

            this.toggleNav(document.querySelector('[href="#' + this.id + '"]').parentElement);
        }.bind(this));
    }

    toggleNav(target) {
        target.classList.toggle('is-active');

        document.querySelector('body').classList.toggle('is-hidden');
    }
}

class LangNav {

    id;

    constructor(id) {
        this.id = id;

        document.querySelector('[href="#' + this.id + '"]').addEventListener('click', function (event) {
            event.preventDefault();

            this.toggleNav(document.querySelector('[href="#' + this.id + '"]').parentElement);
        }.bind(this));
    }

    toggleNav(target) {
        target.classList.toggle('is-active');
    }
}

class Inquiry {

    id;
    component;
    table;
    score;

    constructor(id, table) {
        this.id = id;
        this.component = document.getElementById(this.id);
        this.table = table;
        this.score = {};

        this.component.querySelectorAll('.js-answer').forEach(function (el) {
            el.querySelector('input').addEventListener('change', function (el) {
                this.score[el.target.name] = parseFloat(el.target.value);
                this.updateScore(this.score);

                this.scrollToNext(el);
            }.bind(this));
        }.bind(this))
    }

    updateScore(score) {
        let totalScore = 0;

        Object.values(score).forEach(value => {
            totalScore += value;
        });

        let averageScore = parseInt(Math.ceil(totalScore / Object.keys(score).length));
        let percentage = parseInt(totalScore / Object.keys(score).length / 5 * 100);
        let label = this.table[averageScore];

        this.component.querySelector('.js-score-percentage').innerHTML = percentage + "%";
        this.component.querySelector('.js-score-label').innerHTML = label;
        this.component.querySelector('.js-score-bar').style.width = percentage + "%";
    }

    scrollToNext(el) {
        let currentQuestion = el.target.closest('.element-question');
        let nextQuestion = currentQuestion.nextElementSibling;
        let offset = $(nextQuestion).offset().top - parseInt(window.getComputedStyle(nextQuestion).getPropertyValue('margin-top')) - document.getElementById('header').offsetHeight;

        if (nextQuestion.classList.contains('element-question')) {
            window.scrollTo({
                top: offset,
                left: 0,
                behavior: 'smooth'
            });
        }
    }
}

class MagnificPopup {

    id;

    constructor(id) {
        this.id = id;

        $(this.id).magnificPopup({
            delegate: 'a',
            type: 'image'
        });
    }
}

class Carousel {

    id;

    constructor(id) {
        this.id = id;

        console.log('tetten');

        $(this.id).slick({
            infinite: true,
            autoplay: true,
            arrows: false,
            dots: false,
            slidesToShow: 7,
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
            ],
        });
    }
}

// class StickyScore {

//     id;
//     component;
//     offsetTop;
//     offsetBottom;
//     componentScore;
//     offsetScoreTop;
//     offsetScoreBottom;

//     constructor(id) {
//         this.id = id;
//         // this.component = document.getElementById(this.id);
//         // this.componentScore = this.component.querySelector('.js-score');

//         this.offsetTop = parseInt($('#eid-2').offset().top);

//         $(window).on('load scroll resize', function () {
//             let scrollOffset = parseInt($(window).scrollTop());

//             console.log('container offset: ' + this.offsetTop)
//             // this.offsetScoreTop = parseInt(this.componentScore.getBoundingClientRect().top);
//             // console.log('score offset: ' + this.offsetScoreTop + ' - ' + this.offsetScoreBottom)
//             console.log('page offset: ' + scrollOffset);
//             console.log($(window).height());
//         }.bind(this));
//     }
// }